
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import logoTribuHeader from '../../assets/icons/logo-header.png';
import '../../styles/components/header.scss';


export const Header = () => (
	<Navbar
		expand="lg"
		style={{ backgroundColor: '#0F0816' }}
		fixed="top"
		variant="dark">
		<Container>
			<Navbar.Brand href="/">
				<Link to='/'>
					<img
						src={logoTribuHeader}
						width="130"
						height="50"
						className="d-inline-block align-top logo_header"
						alt="NOVA Logo"
					/>
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle />
			<Navbar.Collapse className="justify-content-end navBar">
				<Nav
					className="me-auto my-2 my-lg-0"
					style={{ maxHeight: '100px' }}
					navbarScroll
				>
					<span className='nav_item'>
						<Link to="/">Página principal</Link>
					</span>
					<span className='nav_item'>
						<Link to="/donaciones">Donaciones</Link>
					</span>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>
);
