import React, { useState } from 'react';

import { Banner } from './components/banner';
import { bannerImages, lifeNecklaceImages, sterilizationImages, educationImages } from './data';
import donationIcon from '../../assets/icons/donation.png';
import arcaLuminosaLogo from '../../assets/images/arca-luminosa-logo.png';
import tribuImage from '../../assets/images/tribu-photo.png';

import { ImagesCarousel } from '../../components/carousel';
import { DonationsButton } from '../../components/donations-button';
import { Section } from '../../components/section';
import { useScrollToTop } from '../../hooks/scroll-to-top';


import '../../styles/pages/home.scss';


export const Home = () => {
	const [isButtonClicked, setIsButtonClicked] = useState(false);
	useScrollToTop(); // Hace scroll para arriba al cambiar de página

	return (
		<main>
			<section className='banner_container'>
				<Banner carouselItems={bannerImages} />
			</section>
			<Section
				title='Quiénes Somos'
				brushStrokeNumber={1}
				backgroundColor='linear-gradient(to right top, #0f0816, #1e152d, #2e1e46, #3f265f, #522e7a)'
			>
				<span className='section_image'>
					<img src={tribuImage} alt="Imagen tribu nova" />
				</span>
				<span className='section_text'>
					<p>
						<b>NOVA</b> es un grupo de personas que se autodenomina tribu porque nos consideramos una <b>familia poderosa </b>
								que busca ayudar al medio ambiente. Actualmente trabajamos en colaboración con la fundación <b>Arca Luminosa</b>.
					</p>
				</span>
			</Section>
			<Section
				title='Arca Luminosa'
				brushStrokeNumber={1}
				backgroundColor='linear-gradient(to right top, #353f0d, #4d5c14, #677a1a, #829a21, #9ebb28)'
			>
				<span className='section_text'>
					<p>
						<b>Arca Luminosa</b> fundada en 2017, tiene un propósito noble: iluminar la vida de los animales callejeros en Colombia. Su labor se enfoca en varios frentes para mejorar la situación de estos seres valiosos:
					</p>
				</span>
				<span className='section_image' id='logo_arcoLuminosa'>
					<img src={arcaLuminosaLogo} alt="Logo Arca Luminosa" />
				</span>
			</Section>
			<Section
				title='Collares De Vida'
				brushStrokeNumber={1}
				backgroundColor='linear-gradient(to right top, #0d123f, #003a6b, #00648f, #0090a7, #28bbb4)'
			>
				<span className='section_image'>
					<ImagesCarousel carouselItems={lifeNecklaceImages}/>
				</span>
				<span className='section_text'>
					<p>
							Ante la problemática de los perritos callejeros que <b>corren el riesgo</b> de ser atropellados, Arca Luminosa ideó los Collares de Vida. Estos collares reflectivos permiten visibilizar a los animales en la oscuridad. Cada collar lleva la frase “No estoy perdido, busco un hogar”, promoviendo la adopción de estos peludos. Hasta ahora, <b>han colocado más de 2,800</b> collares de vida en Bogotá.
					</p>
				</span>
			</Section>
			<Section
				title='Esterilización Con Amor'
				brushStrokeNumber={1}
				backgroundColor='linear-gradient(to right top, #0d123f, #691f5c, #ba375a, #f07142, #fbbe24)'
			>
				<span className='section_text'>
					<p>
							Arca Luminosa está comprometida a cambiar la realidad de los animales callejeros mediante la esterilización. Realizan <b>jornadas
							gratuitas de esterilización</b>  para perros y gatos en zonas vulnerables. Esto ayuda a evitar el abandono, la sobrepoblación y el maltrato. Al cierre del 2020, ya habían <b>esterilizado a 1.459 animales</b>.
					</p>
				</span>
				<span className='section_image'>
					<ImagesCarousel carouselItems={sterilizationImages}/>
				</span>
			</Section>
			<Section
				title='Educación y Conciencia'
				brushStrokeNumber={1}
				backgroundColor='linear-gradient(to right top, #62230e, #8b4d24, #b3793d, #d8a95a, #fadc7c)'
			>
				<span className='section_image'>
					<ImagesCarousel carouselItems={educationImages}/>
				</span>
				<span className='section_text'>
					<p>
						<b>A través de talleres pedagógicos</b> de #AmorAnimal, Arca Luminosa construye consciencia, amor y respeto por los animales. Capacitan a jóvenes y adultos en derecho animal, tenencia responsable y rescate. Además, han llevado a cabo la Jornada Misión Salva Chandas, esterilizando 600 animales desde Santa Marta hasta la Alta Guajira.
					</p>
				</span>
			</Section>
			<div className='donationsButton_Container'>
				{
					!isButtonClicked ? (
						<button type='button' onClick={() => setIsButtonClicked(true)}>
							<img src={donationIcon} alt="Donation icon" />
						</button>
					) : (
						<span className='slide-in'>
							<DonationsButton
								fontSize='2rem'
								url='/donaciones'
								isInternalRoute
							/>
						</span>
					)
				}
			</div>
		</main>
	);
};
