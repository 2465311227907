import Carousel from 'react-bootstrap/Carousel';

import { CarouselItem } from '../../types/components/carousel';


export const ImagesCarousel: React.FC<{ carouselItems: CarouselItem[]; }> = ({ carouselItems }) => {
	const setCarouselItems = () => carouselItems.map((item) => (
		<Carousel.Item key={item.id}>
			<img src={item.image} alt={item.alt} />
			{
				item.caption.shown && (
					<Carousel.Caption>
						<h3>{item.caption.title ?? ''}</h3>
						<p>{item.caption.description ?? ''}</p>
					</Carousel.Caption>
				)
			}
		</Carousel.Item>
	));

	return (
		<Carousel>
			{
				setCarouselItems()
			}
		</Carousel>
	);
};
