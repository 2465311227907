import Carousel from 'react-bootstrap/Carousel';

import logoArcaLuminosa from '../../../../assets/icons/logo-arca-luminosa.png';
import logoNovaDark from '../../../../assets/icons/logo-nova-dark-bg.png';
import logoNovaLight from '../../../../assets/icons/logo-nova-light-bg.png';
import '../../../../styles/components/carousel.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NovaLogo } from '../../../../types/components/carousel';
import type { BannerItem } from '../../data/data';


interface CarouselProps {
	carouselItems: BannerItem[];
}

export const Banner: React.FC<CarouselProps> = ({ carouselItems }: CarouselProps) => {
	const setCarouselItems = (items: BannerItem[]) => items.map((item): React.ReactNode => (
		<Carousel.Item key={item.id}>
			<div className='item_container'>
				<span className='image_container'>
					<img
						src={item.image}
						alt={item.alt}
						className='carousel_image'
					/>
				</span>
				{
					item.logos.shown &&
					(
						<div className='logos_container'>
							<span className='overlay_nova_logo' id='overlay_nova_logo'>
								<img
									src={item.logos.novaVersion === NovaLogo.Dark ? logoNovaDark : logoNovaLight}
									alt="logo nova"
									id='logo_image'
								/>
							</span>
							<span className='overlay_arcaLuminosa_logo' id='overlay_arcaLuminosa_logo'>
								<img
									src={logoArcaLuminosa}
									alt="logo nova"
									id='logo_image'
								/>
							</span>
						</div>
					)
				}
			</div>
		</Carousel.Item>
	));

	return (
		<Carousel>
			{
				setCarouselItems(carouselItems)
			}
		</Carousel>
	);
};
