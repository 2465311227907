import '../../styles/components/donationsButton.scss';
import { Link } from 'react-router-dom';


interface DonationsButtonProps {
	fontSize: string;
	url: string;
	isInternalRoute: boolean;
}

export const DonationsButton: React.FC<DonationsButtonProps> = ({ fontSize, url, isInternalRoute }) => (
	<div>
		{ isInternalRoute ?
			(
				<Link
					to={url}
					className="glow-on-hover"
					type="button"
				>
					<p style={{ 'fontSize' : fontSize ?? '1.5rem' }}>Donar</p>
				</Link>
			) :
			(
				<a
					href={url}
					target="_blank"
					rel="noopener noreferrer"
					className="glow-on-hover"
					type="button"
				>
					<p style={{ 'fontSize' : fontSize ?? '1.5rem' }}>Donar</p>
				</a>
			)
		}
	</div>
);
