import brushStroke1 from '../../assets/icons/brush-stroke-1.png';
import brushStroke2 from '../../assets/icons/brush-stroke-2.png';
import brushStroke3 from '../../assets/icons/brush-stroke-3.png';
import brushStroke4 from '../../assets/icons/brush-stroke-4.png';
import brushStroke5 from '../../assets/icons/brush-stroke-5.png';
import '../../styles/components/section.scss';


interface SectionProps {
	title: string;
	children: React.ReactNode;
	brushStrokeNumber: number;
	backgroundColor: string;
}

export const Section: React.FC<SectionProps> = ({
	title,
	children,
	brushStrokeNumber,
	backgroundColor
}) => {
	const getBrushStroke = (number: number) => {
		switch (number) {
		case 1:
			return brushStroke1;
		case 2:
			return brushStroke2;
		case 3:
			return brushStroke3;
		case 4:
			return brushStroke4;
		case 5:
			return brushStroke5;
		default:
			return brushStroke1;
		}
	};

	return (
		<section style={{ background: backgroundColor }}>
			<div className='content_container'>
				<div className='title_container'>
					<h2 className='section_title'>{ title }</h2>
					<img
						src={ getBrushStroke(brushStrokeNumber) }
						alt="brush stroke"
						className='brush_stroke'
					/>
				</div>
				<div className='section_content'>
					{ children }
				</div>
			</div>
		</section>
	);
};
