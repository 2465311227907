// eslint-disable-next-line no-shadow
export enum NovaLogo {
	None = 'none',
	Dark = 'dark',
	Light = 'light',
}

export interface CarouselItem {
	id: number;
	image: string;
	alt: string;
	caption: {
		shown: boolean;
		title?: string;
		description?: string;
	};
}
