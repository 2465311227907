import bannerImage1 from '../../../assets/images/banner/banner1.jpg';
import bannerImage2 from '../../../assets/images/banner/banner2.jpg';
import bannerImage3 from '../../../assets/images/banner/banner3.jpg';
import education1 from '../../../assets/images/education/education1.jpg';
import education2 from '../../../assets/images/education/education2.jpg';
import education3 from '../../../assets/images/education/education3.jpg';
import education4 from '../../../assets/images/education/education4.jpg';

import lifeNecklaceImage1 from '../../../assets/images/lifeNeckLaces/collar-de-vida1.jpg';
import lifeNecklaceImage2 from '../../../assets/images/lifeNeckLaces/collar-de-vida2.jpg';
import lifeNecklaceImage3 from '../../../assets/images/lifeNeckLaces/collar-de-vida3.jpg';
import lifeNecklaceImage4 from '../../../assets/images/lifeNeckLaces/collar-de-vida4.jpg';
import sterilizationImage1 from '../../../assets/images/sterilizations/sterilization1.jpg';
import sterilizationImage2 from '../../../assets/images/sterilizations/sterilization2.jpg';
import sterilizationImage3 from '../../../assets/images/sterilizations/sterilization3.jpg';
import sterilizationImage4 from '../../../assets/images/sterilizations/sterilization4.jpg';

import { CarouselItem, NovaLogo } from '../../../types/components/carousel';


export interface BannerItem {
	id: number;
	image: string;
	alt: string;
	logos: {
		shown: boolean;
		novaVersion: NovaLogo;
	};
}

export const bannerImages: BannerItem[] = [
	{
		id: 1,
		image: bannerImage1,
		alt: 'banner 1',
		logos: {
			shown: true,
			novaVersion: NovaLogo.Light,
		},
	},
	{
		id: 2,
		image: bannerImage2,
		alt: 'banner 2',
		logos: {
			shown: true,
			novaVersion: NovaLogo.Dark,
		},
	},
	{
		id: 3,
		image: bannerImage3,
		alt: 'banner 3',
		logos: {
			shown: true,
			novaVersion: NovaLogo.Dark,
		},
	},
];

export const lifeNecklaceImages: CarouselItem[] = [
	{
		id: 1,
		image: lifeNecklaceImage1,
		alt: 'Collar de vida',
		caption: {
			shown: false
		}
	},
	{
		id: 2,
		image: lifeNecklaceImage2,
		alt: 'Collar de vida',
		caption: {
			shown: false
		}
	},
	{
		id: 3,
		image: lifeNecklaceImage3,
		alt: 'Collar de vida',
		caption: {
			shown: false
		}
	},
	{
		id: 4,
		image: lifeNecklaceImage4,
		alt: 'Collar de vida',
		caption: {
			shown: false
		}
	}
];

export const sterilizationImages: CarouselItem[] = [
	{
		id: 1,
		image: sterilizationImage1,
		alt: 'Esterilización con amor',
		caption: {
			shown: true,
			title: 'Las comunidades llegan a las jornadas desde muy temprano'
		}
	},
	{
		id: 2,
		image: sterilizationImage2,
		alt: 'Esterilización con amor',
		caption: {
			shown: true,
			title: 'Nos aseguramos de preparar muy bien a nuestros pacientes'
		}
	},
	{
		id: 3,
		image: sterilizationImage3,
		alt: 'Esterilización con amor',
		caption: {
			shown: false
		}
	},
	{
		id: 4,
		image: sterilizationImage4,
		alt: 'Esterilización con amor',
		caption: {
			shown: true,
			title: 'Cada paciente es tratado con amor y respeto'
		}
	}
];

export const educationImages: CarouselItem[] = [
	{
		id: 1,
		image: education1,
		alt: 'Educación y consciencia',
		caption: {
			shown: false
		}
	},
	{
		id: 2,
		image: education2,
		alt: 'Educación y consciencia',
		caption: {
			shown: false
		}
	},
	{
		id: 3,
		image: education3,
		alt: 'Educación y consciencia',
		caption: {
			shown: false
		}
	},
	{
		id: 4,
		image: education4,
		alt: 'Educación y consciencia',
		caption: {
			shown: false
		}
	}
];
