import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Layout } from '../components/layout';

import { Donations } from '../pages/donations';
import { Home } from '../pages/home';


export const App = () => (
	<BrowserRouter>
		<Layout>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="donaciones" element={<Donations />} />
			</Routes>
		</Layout>
	</BrowserRouter>
);
