import React from 'react';

import instagramIcon from '../../assets/icons/icon-instagram.png';
import tiktokIcon from '../../assets/icons/icon-tik-tok.png';
import logoImage from '../../assets/icons/logo-nova-light-bg.png';

import '../../styles/components/footer.scss';


export const Footer: React.FC = () => (
	<footer>
		<div className='footer_container'>
			<div className='logo_container'>
				<img
					src={logoImage}
					alt="Your Logo"
				/>
			</div>
			<div className='social_media_container'>
				<span className='social_media'>
					<a
						href="https://www.tiktok.com/@tribunova49?_t=8ktJQ8RyRWA&_r=1"
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={tiktokIcon} alt="icono tik tok" />
						<p>TribuNova49</p>
					</a>
				</span>
				<span className='social_media'>
					<a
						href="https://www.instagram.com/tribunova49/"
						target='_blank'
						rel='noopener noreferrer'>
						<img src={instagramIcon} alt="icono " />
						<p>tribuNova49</p>
					</a>
				</span>
			</div>
		</div>
	</footer>
);
