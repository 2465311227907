import { Footer } from '../footer';
import { Header } from '../header';


export const Layout: React.FC<{ children: React.ReactNode; }> = ({ children }) => (
	<div className="App">
		<Header />
		{children}
		<Footer />
	</div>
);
