/* eslint-disable react/style-prop-object */
import React from 'react';

import donationsAmountImage from '../../assets/icons/donation-amount.png';
import donationsImage from '../../assets/images/donations/donations-image.png';

import { DonationsButton } from '../../components/donations-button';
import { useScrollToTop } from '../../hooks/scroll-to-top';

import '../../styles/pages/donations.scss';


const NOVA_NIBI_URL = 'https://nibi.com.co/landing/65f5aa45d002480012da202d';

export const Donations: React.FC = () => {
	useScrollToTop(); // Hace scroll para arriba al cambiar de página

	return (
		<main>
			<div className='bubbles_container'>
				<div className="bubbles">
					<span style={{ '--i': '6' } as React.CSSProperties}></span>
					<span style={{ '--i': '7' } as React.CSSProperties}></span>
					<span style={{ '--i': '19' } as React.CSSProperties}></span>
					<span style={{ '--i': '5' } as React.CSSProperties}></span>
					<span style={{ '--i': '9' } as React.CSSProperties}></span>
					<span style={{ '--i': '18' } as React.CSSProperties}></span>
					<span style={{ '--i': '13' } as React.CSSProperties}></span>
					<span style={{ '--i': '11' } as React.CSSProperties}></span>
					<span style={{ '--i': '14' } as React.CSSProperties}></span>
					<span style={{ '--i': '15' } as React.CSSProperties}></span>
					<span style={{ '--i': '17' } as React.CSSProperties}></span>
					<span style={{ '--i': '20' } as React.CSSProperties}></span>
					<span style={{ '--i': '13' } as React.CSSProperties}></span>
				</div>
				<div className='donationsContent_container'>
					<div className='message_container'>
						<span className='title_container'>
							<h1>Tu ayuda</h1>
						</span>
						<h2>Es muy importante</h2>
						<div className='call_to_action'>
							<span className='image_container'>
								<img src={donationsAmountImage} alt="" />
							</span>
							<span className='main_message'>
								<p>¡Dona hoy y deja tu huella de amor y compasión!</p>
							</span>
						</div>
					</div>
					<div className='image_container'>
						<img src={donationsImage} alt="" />
					</div>
					<div className='button_container'>
						<DonationsButton
							fontSize='1.5rem'
							url={NOVA_NIBI_URL}
							isInternalRoute={false}
						/>
					</div>
				</div>
			</div>
		</main>
	);
};
